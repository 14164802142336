/*
 * @Author: NULL 1628069508@qq.com
 * @Date: 2022-11-25 16:29:01
 * @LastEditors: NULL 1628069508@qq.com
 * @LastEditTime: 2022-11-25 16:41:35
 * @Description: 馆藏文物相关接口配置文件
 */
import request from '@/utils/request'

// 分页
export function getArt(params) {
    return request({
        url: '/api/cultural',
        method: 'get',
        params
    })
}

// 接口 --- 根据id获取数据
export function getNewsCultural(id) {
    return request({
        url: '/api/cultural/' + id,
        method: 'get'
    })
}

// 接口 --- 获取馆藏文物分类
export function getTreasureCategory(data) {
    return request({
      url: '/api/cultural/category',
      method: 'get',
      params: data
    })
  }