<!--
 * @Author: LK-5919 1628069508@qq.com
 * @Date: 2022-11-21 15:13:04
 * @LastEditors: LK-5919 1628069508@qq.com
 * @LastEditTime: 2022-11-27 11:20:40
-->
<template>
  <svg :class="svgClass" aria-hidden="true">
    <use :xlink:href="iconName" rel="external nofollow"></use>
  </svg>
</template>
 
<script>
import { computed } from "@vue/reactivity";
export default {
  name: "baseSvgIcon",
  props: {
    iconClass: { type: String },
    className: { type: String },
  },
  setup(props) {
    const iconName = computed(() => {
      return props.iconClass ? `#icon-${props.iconClass}` : "#icon";
    });
    const svgClass = computed(() => {
      return props.className ? "svg-icon " + props.className : "svg-icon";
    });
    return { iconName, svgClass };
  },
};
</script>
 
<style scoped lang="less">
.svg-icon {
  width: 1em;
  height: 1em;
  vertical-align: -0.15em;
  fill: currentColor;
  overflow: hidden;
  use {
    width: 100%;
    height: 100%;
  }
}
</style>