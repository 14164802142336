/*
 * @Author: NULL 1628069508@qq.com
 * @Date: 2022-11-22 09:06:01
 * @LastEditors: NULL 1628069508@qq.com
 * @LastEditTime: 2022-11-29 15:57:39
 */
/***
 * @Author: ZWJ
 * @Date: 2022-11-17 11:24:40
 * @LastEditTime: 2022-11-17 14:51:39
 * @LastEditors: ZWJ
 * @Description:
 */
import "babel-polyfill";
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import { createApp } from 'vue'
import App from './App.vue'
import { router } from './router'
import i18n from './assets/language/i18n.js'
import element from 'element-plus'
import SvgIcon from './components/SvgIcon'

import 'element-plus/theme-chalk/index.css'
import './config/rem'
import '@/assets/css/base.css'
import "../src/assets/font/stylesheet.css"
import './assets/icons/index.js'
import PeterAlbusVue from 'vue3-social-share';
import 'vue3-social-share/lib/index.css'
const app = createApp(App)
app.component('svg-icon', SvgIcon)
app.use(router).use(i18n).use(element);

app.config.globalProperties.$i18n = i18n

app.mount('#app')
