/*
 * @Author: LK-5919 1628069508@qq.com
 * @Date: 2022-11-28 21:04:25
 * @LastEditors: LK-5919 1628069508@qq.com
 * @LastEditTime: 2022-11-28 21:06:34
 * @Description: 全局事件总线
 */
import mitt from 'mitt';
 
const emitter = mitt();
 
export default emitter;