<!--
 * @Author: ZWJ
 * @Date: 2022-11-18 17:28:18
 * @LastEditTime: 2022-11-27 13:33:45
 * @LastEditors: LK-5919 1628069508@qq.com
 * @Description: 进入页
-->
<template>
  <!-- <div id="app"> -->
  <myHeader />
  <router-view v-if="isRouterAlive"></router-view>
  <Footer></Footer>
  <!-- </div> -->
</template>
<script>
import myHeader from "./layout/Header.vue";
import Footer from "./layout/Footer.vue";

export default {
  name: "App",
  components: {
    myHeader,
    Footer,
  },
  provide() {
    return {
      reload: this.reload,
    };
  },
  data() {
    return {
      isRouterAlive: true,
    };
  },

  methods: {
    reload() {
      this.isRouterAlive = false;
      this.$nextTick(function () {
        this.isRouterAlive = true;
      });
    },
  },
};
</script>
<style lang="less">
#app {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow-x: hidden;
  background: url("../src/assets/img/main-bg.png");
  background-size: 100% 100%;
  background-repeat: no-repeat;
}
</style>
