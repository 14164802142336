/*
 * @Author: LK-5919 1628069508@qq.com
 * @Date: 2022-11-26 11:05:15
 * @LastEditors: FGJ
 * @LastEditTime: 2022-12-12 18:16:46
 * @FilePath: \wenwuweb\src\utils\request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
/***
 * @Author: ZWJ
 * @Date: 2022-11-25 09:23:42
 * @LastEditTime: 2022-11-25 09:23:42
 * @LastEditors: ZWJ
 * @Description: axios配置
 */

import axios from 'axios'
import { ElMessage } from 'element-plus'

// 定义默认地址
// const baseURL = 'https://302q04i866.goho.co'
const baseURL = window.g.baseURL;
// 导入地址
export { baseURL }

// 定义中英文
const language = 'zh_CN'

// 创建一个 axios 实例
const service = axios.create({
  baseURL, // 所有的请求地址前缀部分
  timeout: 60000, // 请求超时时间毫秒
  withCredentials: true, // 异步请求携带cookie
  headers: {
    // 设置后端需要的传参类型
    // 'Content-Type': 'application/json',
    //  'token': 'your token',
    //  'X-Requested-With': 'XMLHttpRequest',
  },
})

// 添加请求拦截器
service.interceptors.request.use(
  function (config) {
    // 在发送请求之前做些什么
    config.params = {
      ...config.params,
      language,
    }
    return config
  },
  function (error) {
    // 对请求错误做些什么
    console.log(error)
    return Promise.reject(error)
  }
)

// 添加响应拦截器
service.interceptors.response.use(
  function (response) {
    // console.log(response)
    // 2xx 范围内的状态码都会触发该函数。
    // 对响应数据做点什么
    // dataAxios 是 axios 返回数据中的 data
    const dataAxios = response.data
    // 这个状态码是和后端约定的
    //  const code = dataAxios.reset
    return dataAxios
  },
  function (error) {
    // 超出 2xx 范围的状态码都会触发该函数。
    // 对响应错误做点什么
    console.log(error.response)
    ElMessage({
      message: error.response.data.message || error.response.data.error,
      type: 'warning',
    })
    return Promise.reject(error)
  }
)
export default service
