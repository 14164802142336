import { createI18n } from 'vue-i18n' //引入vue-i18n组件
import messages from './index'
const language = (
    (navigator.language ? navigator.language : navigator.userLanguage) || "zh"
).toLowerCase();
// console.log(localStorage.getItem('lan'));
const i18n = createI18n({
    fallbackLocale: 'en',
    globalInjection: true,
    legacy: false, // you must specify 'legacy: false' option
    locale: localStorage.getItem('lan') ? localStorage.getItem('lan') : language.split("-")[0] || "zh",
    messages,
});

export default i18n