/*
 * @Descripttion: 
 * @version: 
 * @Author: FGJ
 * @Date: 2022-11-28 08:56:17
 * @LastEditors: FGJ
 * @LastEditTime: 2022-11-29 09:56:33
 */
import { createRouter, createWebHashHistory } from "vue-router";
import routes from "./router";

const router = new createRouter({
    history: createWebHashHistory(),
    // mode: 'hash',
    routes
})
router.afterEach(() => {
    window.scrollTo(0, 0);
})
export {
    router
}
