/*
 * @Author: NULL 1628069508@qq.com
 * @Date: 2022-11-25 13:55:06
 * @LastEditors: NULL 1628069508@qq.com
 * @LastEditTime: 2022-11-25 14:40:35
 * @Description: 学术研究相关接口配置文件
 */
import request from '@/utils/request'

// 接口 --- 分页获取学术分类
export function getAcademicCategory(data) {
    return request({
      url: '/api/researchCategory',
      method: 'get',
      params: data
    })
  }
// 接口 --- 根据id获取分类数据
export function getAcademicCategoryById(id) {
    return request({
      url: '/api/researchCategory/' + id,
      method: 'get'
    })
  }
// 接口 --- 分页获取学术研究列表
export function getAcademicPage(data) {
    return request({
      url: '/api/research',
      method: 'get',
      params: data
    })
  }
// 接口 --- 根据id获取学术研究详情
export function getAcademicById(id) {
    return request({
      url: '/api/research/' + id,
      method: 'get'
    })
  }