/*
 * @Author: NULL 1628069508@qq.com
 * @Date: 2022-11-25 09:33:47
 * @LastEditors: NULL 1628069508@qq.com
 * @LastEditTime: 2022-11-25 11:30:02
 * @Description: 新闻相关接口配置文件
 */
import request from '@/utils/request'

// 接口 --- 分页获取新闻分类
export function getNewsCategoryPage(data) {
    return request({
      url: '/api/newsCategory',
      method: 'get',
      params: data
    })
  }
// 接口 --- 根据新闻分类id获取数据
export function getNewsCategoryById(id) {
    return request({
      url: '/api/newsCategory/' + id,
      method: 'get'
    })
  }
// 接口 --- 分页获取新闻
export function getNewsPage(data) {
    return request({
      url: '/api/news',
      method: 'get',
      params: data
    })
  }
// 接口 --- 根据新闻id获取新闻详情
export function getNewsById(id) {
    return request({
      url: '/api/news/' + id,
      method: 'get'
    })
  }