<!--
 * @Author: LK-5919 1628069508@qq.com
 * @Date: 2022-11-26 23:50:57
 * @LastEditors: FGJ
 * @LastEditTime: 2022-12-12 17:13:26
 * @Description: 页面全局底部组件
-->
<template>
  <div class="norem-footer-wrap">
    <div class="footer-banner-wrap">
      <div class="footer-banner">
        <div class="info-left info" @click="goCollection">
          <span class="info-text">
            {{ $t(`message.Collection`) }}
          </span>
          <img src="../assets/img/icon-more02.png" class="info-icon" />
        </div>
        <div class="line"></div>
        <div class="info-right info" @click="goIntroduction">
          <span class="info-text">
            {{ $t(`message.Collectionappraisal`) }}
          </span>
          <img src="../assets/img/icon-more02.png" class="info-icon" />
        </div>
      </div>
    </div>
    <div class="footer-info-wrap">
      <el-row class="footer-info">
        <el-col class="left-info" :xs="24" :sm="24" :md="24" :lg="10" :xl="10">
          <img src="../assets/img/logo.png" class="footer-logo" />
          <div class="footer-nav-type">
            <span class="type-text">
              {{ $t(`message.SiteMap`) }}
            </span>
            <span class="type-line">|</span>
            <span class="type-text">
              {{ $t(`message.RelatedLinks`) }}
            </span>
            <span class="type-line">|</span
            ><span class="type-text">
              {{ $t(`message.CopyrightNotice`) }}
            </span>
            <span class="type-line">|</span
            ><span class="type-text">
              {{ $t(`message.MessageBoard`) }}
            </span>
            <span class="type-line">|</span
            ><span class="type-text">
              {{ $t(`message.contactus`) }}
            </span>
            <span class="type-line">|</span
            ><span class="type-text">
              {{ $t(`message.Aboutus`) }}
            </span>
          </div>
        </el-col>
        <el-col class="mid-info" :xs="24" :sm="24" :md="24" :lg="2" :xl="2">
          <img src="../assets/img/二维码.png" class="img-QRCode" />
          <div class="QRCode-text">
            {{ $t(`message.Scanthecodetoviewthemobilestation`) }}
          </div>
        </el-col>
        <el-col
          class="right-info-wrap"
          :xs="24"
          :sm="24"
          :md="24"
          :lg="10"
          :xl="10"
        >
          <div class="right-info">
            <div class="time-range">
              <div class="time">
                <div class="time-text">
                  {{ $t(`message.opentime`) }}
                </div>
                <div class="time-clock">9:00</div>
              </div>
              <!-- <div class="guide-icon"> -->
              <!-- <svg-icon icon-class="guide"></svg-icon> -->
              <img src="../assets/img/guide-icon.png" class="guide-icon" />
              <!-- </div> -->
              <div class="time">
                <div class="time-text">
                  {{ $t(`message.Stopenteringthelibrary`) }}
                </div>
                <div class="time-clock">16:00</div>
              </div>
              <!-- <div class="guide-icon"> -->
              <img src="../assets/img/guide-icon.png" class="guide-icon" />
              <!-- </div> -->
              <div class="time">
                <div class="time-text">
                  {{ $t(`message.ClosingHours`) }}
                </div>
                <div class="time-clock">17:00</div>
              </div>
            </div>
            <div class="right-tip-info">
              {{ $t(`message.FreefromTuesdaytoSunday`) }}（{{
                $t(`message.closedonMonday`)
              }},{{ $t(`message.exceptholidays`) }}）
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
    <div class="footer-copyright-info">
      Copyright©2022 | {{ $t(`message.GandharaArtMuseum`) }}
      {{ $t(`message.copyright`) }}
      <a href="https://beian.miit.gov.cn/">浙ICP备2022035555号-1</a>
    </div>
  </div>
</template>

<script>
import { useI18n } from "vue-i18n"; //要在js中使用国际化
import "element-plus/theme-chalk/display.css";
import $bus from "@/utils/eventBus";
export default {
  name: "Footer",
  setup() {
    const { t } = useI18n();
  },
  data() {
    return {};
  },
  created() {},
  mounted() {},
  computed: {},
  watch: {},
  methods: {
    goCollection() {
      this.$router.push("/treasure");
      $bus.emit("changeMenu", 3);
    },
    goIntroduction() {
      this.$router.push({ path: "/introduction", query: { choose: 3 } });
      $bus.emit("changeMenu", 2);
    },
  },
};
</script>

<style lang="less" scoped>
.norem-footer-wrap {
  margin-top: 150px;
  box-sizing: border-box;
  width: 100%;

  .footer-banner-wrap {
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    background-image: url("../assets/img/footer-bg.png");
    width: 100%;

    .footer-banner {
      transform: translateY(-50%);
      display: flex;
      align-items: center;
      justify-content: center;
      box-sizing: border-box;
      width: 1680px;
      height: 200px;
      background-color: rgb(50, 50, 50);
      border-radius: 8px 8px 8px 8px;
      background-image: url("../assets/img/footer-img.png");
      background-size: cover;
      background-repeat: no-repeat;

      .line {
        width: 1px;
        height: 60px;
        opacity: 0.8;
        background-color: #ffffff;
      }

      .info {
        display: flex;
        flex-grow: 1;
        align-items: center;
        justify-content: center;
        height: 100%;

        .info-text {
          height: 40px;
          font-size: 28px;
          font-family: PingFang SC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 40px;
        }

        .info-icon {
          width: 20px;
          animation: fadeOutRight 2s ease-out infinite;
        }

        @keyframes fadeOutRight {
          0% {
            opacity: 1;
            -webkit-transform: translateX(0);
            -ms-transform: translateX(0);
            transform: translateX(0);
          }

          100% {
            opacity: 0;
            -webkit-transform: translateX(20px);
            -ms-transform: translateX(20px);
            transform: translateX(20px);
          }
        }

        &:hover {
          background-color: rgba(0, 0, 0, 0.4);
          cursor: pointer;
        }
      }

      .info-left {
        border-radius: 8px 0px 0px 8px;
      }

      .info-right {
        border-radius: 0px 8px 8px 0px;
      }
    }

    @media screen and (max-width: 992px) {
      .footer-banner {
        height: 150px;

        .info {
          .info-text {
            font-size: 22px;
          }
        }
      }
    }
  }

  .footer-info-wrap {
    width: 100%;
    box-sizing: border-box;
    background-image: url("../assets/img/footer-bg.png");
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: -50px;

    .footer-info {
      box-sizing: border-box;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 1680px;
      align-items: flex-end;
      padding-bottom: 30px;

      .left-info {
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        flex-grow: 1;

        .footer-logo {
          width: 280px;
          margin-bottom: 20px;
        }

        .footer-nav-type {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;

          .type-text {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            opacity: 0.8;

            &:hover {
              cursor: pointer;
              opacity: 1;
            }
          }

          .type-line {
            margin: 0px 5px;
          }
        }
      }

      .mid-info {
        flex-grow: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .img-QRCode {
          width: 100px;
          height: 100px;
          margin-bottom: 10px;
        }

        .QRCode-text {
          font-size: 12px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #ffffff;
          text-align: center;
        }
      }

      .right-info-wrap {
        display: flex;
        flex-grow: 1;
        justify-content: flex-end;

        .right-info {
          display: flex;
          flex-direction: column;
          align-items: center;

          .time-range {
            display: flex;
            justify-content: center;
            align-items: center;

            .time {
              .time-text {
                font-size: 16px;
                font-family: PingFang SC-Regular, PingFang SC;
                font-weight: 400;
                color: #ffffff;
                margin-bottom: 5px;
                text-align: center;
              }

              .time-clock {
                font-size: 36px;
                font-family: D-DIN DIN-Bold, D-DIN DIN;
                font-weight: bold;
                color: #ffffff;
                line-height: 32px;
              }
            }

            .guide-icon {
              padding-top: 20px;
              width: 30px;
              height: 20px;
              margin: 0px 15px;
            }
          }

          .right-tip-info {
            font-size: 14px;
            font-family: PingFang SC-Regular, PingFang SC;
            font-weight: 400;
            color: #ffffff;
            text-align: center;
            margin-top: 40px;
          }
        }
      }

      @media screen and (max-width: 1200px) {
        .left-info {
          align-items: center;
        }

        .mid-info {
          margin: 20px 0px;
          padding-top: 20px;
          padding-bottom: 20px;
          border-top: 1px solid rgba(255, 255, 255, 0.1);
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
        }

        .right-info-wrap {
          justify-content: center;
        }
      }
    }

    @media screen and (max-width: 992px) {
      .footer-info {
        .right-info-wrap {
          .right-info {
            .time-range {
              .time {
                .time-text {
                  font-size: 12px;
                }

                .time-clock {
                  font-size: 28px;
                }
              }

              .guide-icon {
                width: 30px !important;
                height: 20px;
                line-height: 20px;
                margin: 0px 5px;
              }
            }
          }
        }
      }
    }
  }

  .footer-copyright-info {
    box-sizing: border-box;
    // max-width: 1680px;
    padding: 10px 0px;
    // height: 17px;
    line-height: 17px;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: rgba(255, 255, 255, 0.6);
    text-align: center;
    background-image: url("../assets/img/footer-bg.png");
    border-top: 1px solid rgba(255, 255, 255, 0.1);

    span {
      color: #ffffff;
    }
  }

  @media screen and (max-width: 1680px) and (min-width: 1200px) {
    .footer-info-wrap {
      padding: 0 60px;
    }

    .footer-copyright-info {
      padding: 10px 0px;
    }
  }
}

@media screen and (min-width: 1921px) {
  .norem-footer-wrap {
    .footer-banner-wrap {
      .footer-banner {
        width: 87.5%;
      }

      .footer-info-wrap {
        .footer-info {
          width: 87.5%;
        }
      }
    }
  }
}
</style>