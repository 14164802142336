<!-- 顶部导航栏组件 -->
<template>
  <div>
    <div class="norem-header-wrap" :style="{ height: height + 'px' }">
      <el-row class="header">
        <el-col class="left" :xl="3" :lg="4" :md="12" :sm="12" :xs="12">
          <div class="left-logo"></div>
        </el-col>
        <el-col class="mid hidden-md-and-down" :span="16">
          <div class="mid-item" :class="{ active: itemIndex === 1 }" @click="changeItemIndex(1)">
            <span class="el-dropdown-link">
              {{ $t(`message.home`) }}
            </span>
          </div>
          <div class="mid-item" :class="{ active: itemIndex === 2 }" @click="changeItemIndex(2)">
            <span class="el-dropdown-link">
              {{ $t(`message.artIntroduction`) }}
            </span>
          </div>
          <el-dropdown class="mid-item" :class="{ active: itemIndex === 3 }">
            <span class="el-dropdown-link" @click="toFirstMenu(3)">
              {{ $t(`message.Collection`) }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu class="norem-drop-menu">
                <el-dropdown-item class="second-item" @click="changeItemIndex(3, item.name)"
                  v-for="item in treasureCategory" :key="item.id">{{ item.name }}
                </el-dropdown-item>
                <!-- <el-dropdown-item class="second-item" @click="changeItemIndex(3)">{{ $t(`message.bronzeware`) }}
                </el-dropdown-item>
                <el-dropdown-item class="second-item" @click="changeItemIndex(3)">{{ $t(`message.Goldandsilverware`) }}
                </el-dropdown-item>
                <el-dropdown-item class="second-item" @click="changeItemIndex(3)">{{
                    $t(`message.Calligraphyandpainting`)
                }}
                </el-dropdown-item> -->
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown class="mid-item" :class="{ active: itemIndex === 4 }">
            <span class="el-dropdown-link" @click="toFirstMenu(4)">
              {{ $t(`message.NewsCenter`) }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu class="norem-drop-menu">
                <el-dropdown-item class="second-item" @click="changeItemIndex(4, item.name)"
                  v-for="item in newsCategory" :key="item.id">{{ item.name }}
                </el-dropdown-item>
                <!-- <el-dropdown-item class="second-item" @click="changeItemIndex(4)"
                >{{ $t(`message.Industryinformation`) }}
              </el-dropdown-item> -->
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <el-dropdown class="mid-item" :class="{ active: itemIndex === 5 }">
            <span class="el-dropdown-link" @click="toFirstMenu(5)">
              {{ $t(`message.academicresearch`) }}
              <el-icon class="el-icon--right">
                <arrow-down />
              </el-icon>
            </span>
            <template #dropdown>
              <el-dropdown-menu class="norem-drop-menu">
                <el-dropdown-item class="second-item" @click="changeItemIndex(5, item.name)"
                  v-for="item in researchCategory" :key="item.id">{{ item.name }}
                </el-dropdown-item>
                <!-- <el-dropdown-item class="second-item" @click="changeItemIndex(5)"
                >{{ $t(`message.Openclass`) }}
              </el-dropdown-item> -->
              </el-dropdown-menu>
            </template>
          </el-dropdown>
          <div class="mid-item" :class="{ active: itemIndex === 6 }" @click="changeItemIndex(6)">
            <span class="el-dropdown-link">
              {{ $t(`message.publication`) }}
            </span>
          </div>
          <div class="mid-item" :class="{ active: itemIndex === 7 }" @click="changeItemIndex(7)">
            <span class="el-dropdown-link">
              {{ $t(`message.contactus`) }}
            </span>
          </div>
        </el-col>
        <el-col class="right hidden-md-and-down" :span="5" :xl="4" :lg="3">
          <el-input v-model="keyword" class="mid-input" :placeholder="$t(`message.Pleaseenterkeywords`)">
            <template #suffix>
              <el-icon class="el-input__icon" style="font-size: 20px">
                <Search></Search>
              </el-icon>
            </template>
          </el-input>
          <div class="circle-btn">URD</div>
        </el-col>
        <el-col class="right-menu hidden-lg-and-up" :md="12" :sm="12" :xs="12">
          <div class="right-menu-wrap" @click="openDrawer">
            <div></div>
            <div class="mid-line"></div>
            <div></div>
          </div>
        </el-col>
      </el-row>
    </div>
    <!-- 小屏时显示的侧边菜单栏 -->
    <Transition>
      <div class="norem-drawer-wrap" :class="{ showDrawer: showDrawer }">
        <div class="close-drawer">
          <img src="../assets/img/closed.png" class="close-img" @click="closeDrawer" />
        </div>
        <div class="top-logo">
          <img src="../assets/img/logo.png" class="logo" />
        </div>
        <div class="menu-list-wrap">
          <div class="menu-item" @click="changeItemIndex(1)">
            {{ $t(`message.home`) }}
          </div>
          <div class="menu-item" @click="changeItemIndex(2)">
            {{ $t(`message.artIntroduction`) }}
          </div>
          <div class="menu-item-outter">
            <div class="first-menu-item">
              <span @click="showCollection = !showCollection">{{
                  $t(`message.Collection`)
              }}</span>
              <el-icon class="el-icon-right" size="24">
                <CaretBottom />
              </el-icon>
            </div>
            <div class="menu-item-inner" :class="{ openMenu: showCollection }">
              <div class="second-menu-item" @click="changeItemIndex(3, item.name)" v-for="item in treasureCategory"
                :key="item.id">
                {{ item.name }}
              </div>
              <!-- <div class="second-menu-item" @click="changeItemIndex(3)">
                {{ $t(`message.bronzeware`) }}
              </div>
              <div class="second-menu-item" @click="changeItemIndex(3)">
                {{ $t(`message.Goldandsilverware`) }}
              </div>
              <div class="second-menu-item" @click="changeItemIndex(3)">
                {{ $t(`message.Calligraphyandpainting`) }}
              </div> -->
            </div>
          </div>
          <div class="menu-item-outter">
            <div class="first-menu-item">
              <span @click="showNewsCenter = !showNewsCenter">{{
                  $t(`message.NewsCenter`)
              }}</span>
              <el-icon class="el-icon-right" size="24">
                <CaretBottom />
              </el-icon>
            </div>
            <div class="menu-item-inner" :class="{ openMenu: showNewsCenter }">
              <div class="second-menu-item" @click="changeItemIndex(4, item.name)" v-for="item in newsCategory"
                :key="item.id">
                {{ item.name }}
              </div>
              <!-- <div class="second-menu-item" @click="changeItemIndex(4)">
              {{ $t(`message.Industryinformation`) }}
            </div> -->
            </div>
          </div>
          <div class="menu-item-outter">
            <div class="first-menu-item">
              <span @click="showAcademicResearch = !showAcademicResearch">{{
                  $t(`message.academicresearch`)
              }}</span>
              <el-icon class="el-icon-right" size="24">
                <CaretBottom />
              </el-icon>
            </div>
            <div class="menu-item-inner" :class="{ openMenu: showAcademicResearch }">
              <div class="second-menu-item" @click="changeItemIndex(5, item.name)" v-for="item in researchCategory"
                :key="item.id">
                {{ item.name }}
              </div>
              <!-- <div class="second-menu-item" @click="changeItemIndex(5)">
              {{ $t(`message.Openclass`) }}
            </div> -->
            </div>
          </div>
          <div class="menu-item" @click="changeItemIndex(6)">
            {{ $t(`message.publication`) }}
          </div>
          <div class="menu-item" @click="changeItemIndex(7)">
            {{ $t(`message.contactus`) }}
          </div>
        </div>
        <button class="drawer-btn">Switch URD version</button>
      </div>
    </Transition>
  </div>
</template>

<script>
import "element-plus/theme-chalk/display.css";
import { ArrowDown, Search, CaretBottom } from "@element-plus/icons-vue";
import { useI18n } from "vue-i18n"; //要在js中使用国际化
import { getNewsCategoryPage } from "@/api/newsCenter";
import { getAcademicCategory } from "@/api/academicResearch";
import { getTreasureCategory } from "@/api/treasure";
import $bus from "@/utils/eventBus";
export default {
  name: "Header",
  setup() {
    const { t } = useI18n();
  },
  components: {
    ArrowDown,
    Search,
    CaretBottom,
  },
  data() {
    return {
      keyword: "",
      itemIndex: 1,
      showDrawer: false,
      showCollection: false,
      showNewsCenter: false,
      showAcademicResearch: false,
      height: 80,
      flag: true,
      newsCategory: [],
      researchCategory: [],
      treasureCategory: [],
      queryForm: {
        pageSize: 1000,
        pageNum: 1,
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      window.addEventListener("scroll", this.windowScrollListener, true);
    });
  },
  created() {
    if (sessionStorage.getItem("headerIndex") !== undefined) {
      this.itemIndex = Number(sessionStorage.getItem("headerIndex"));
    }
    this.getList();
    // 添加事件监听
    $bus.on("changeMenu", (index) => {
      this.itemIndex = index;
      // 将index存储到sessionStorage中，避免刷新页面后不丢失选中状态
      sessionStorage.setItem("headerIndex", index);
    });
  },
  computed: {},
  watch: {
    $route(to, from) {
      if (to.name == "publication") {
        this.changeItemIndex(6);
      } else if (to.name == "contact") {
        this.changeItemIndex(7);
      } else if (to.name == "home") {
        this.changeItemIndex(1);
      }
    },
  },
  methods: {
    // 一级页面跳转
    toFirstMenu(index) {
      if (index === 3) {
        if (this.$route.path === '/treasure') {
          $bus.emit('changeBreadText', '全部');
        } else {
          this.$router.push('/treasure');
        }
      } else if (index === 4) {
        if (this.$route.path === '/newsCenter') {
          $bus.emit('changeBreadText', '全部');
        } else {
          this.$router.push('/newsCenter');
        }
      } else if (index === 5) {
        if (this.$route.path === '/academicResearch') {
          $bus.emit('changeBreadText', '全部');
        } else {
          this.$router.push('/academicResearch');
        }
      }
      this.itemIndex = index;
      // 将index存储到sessionStorage中，避免刷新页面后不丢失选中状态
      sessionStorage.setItem("headerIndex", index);
      document.documentElement.scrollTop = 0;
    },
    // 请求新闻中心分类
    async getNewsCategoryList() {
      let res = await getNewsCategoryPage(this.queryForm);
      if (res.code === "200") {
        this.newsCategory = res.data.records;
      }
    },
    // 请求学术研究分类
    async getResearchCategoryList() {
      let res = await getAcademicCategory(this.queryForm);
      if (res.code === "200") {
        this.researchCategory = res.data.records;
      }
    },
    // 请求馆藏文物分类
    async getTreasureCategoryList() {
      let res = await getTreasureCategory(this.queryForm);
      if (res.code === "200") {
        this.treasureCategory = res.data.records;
      }
    },
    // 请求全部分类数据
    getList() {
      this.getTreasureCategoryList();
      this.getNewsCategoryList();
      this.getResearchCategoryList();
    },
    changeItemIndex(index, type) {
      this.itemIndex = index;
      // 将index存储到sessionStorage中，避免刷新页面后不丢失选中状态
      sessionStorage.setItem("headerIndex", index);
      if (this.showDrawer) {
        this.showDrawer = false;
      }
      switch (index) {
        case 1:
          this.$router.push("/home");
          break;
        case 2:
          this.$router.push("/introduction");
          break;
        case 3:
          if (this.$route.path === '/treasure') {
            $bus.emit('changeBreadText', type)
          } else {
            this.$router.push({ path: "/treasure", query: { type: type } });
          }
          break;
        case 4:
          if (this.$route.path === '/newsCenter') {
            $bus.emit('changeBreadText', type)
          } else {
            this.$router.push({ path: "/newsCenter", query: { type: type } });
          }
          break;
        case 5:
          if (this.$route.path === '/academicResearch') {
            $bus.emit('changeBreadText', type)
          } else {
            this.$router.push({
              path: "/academicResearch",
              query: { type: type },
            });
          }
          break;
        case 6:
          this.$router.push("/publication");
          break;
        case 7:
          this.$router.push("/contact");
          break;
        default:
          break;
      }
      document.documentElement.scrollTop = 0;
    },
    openDrawer() {
      this.showDrawer = true;
      // this.stopMove();
    },
    closeDrawer() {
      this.showDrawer = false;
      // this.Move();
    },
    //监听窗口滚动
    windowScrollListener() {
      // 节流
      if (this.flag) {
        setTimeout(() => {
          // 500毫秒后在执行
          //获取操作元素最顶端到页面顶端的垂直距离
          let scrollTop =
            document.documentElement.scrollTop ||
            document.body.scrollTop ||
            window.pageYOffset;

          if (scrollTop !== 0) {
            this.height = 60;
          } else {
            this.height = 80;
          }
          this.flag = true;
        }, 100);
      }
      this.flag = false;
    },
  },
};
</script>

<style lang="less" scoped>
.norem-header-wrap {
  // height: 80px;
  width: 100%;
  background: #40312873;
  border-radius: 0px 0px 0px 0px;
  backdrop-filter: blur(60px);
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 70px;
  // position: sticky;
  position: fixed;
  top: 0;
  z-index: 999;
  transition: all 0.3s;

  .header {
    width: 100%;
    box-sizing: border-box;
    display: flex;
    align-items: center;

    .left {
      flex-shrink: 0;
      height: 60px;
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .left-logo {
        width: 180px;
        height: 60px;
        background-size: 100% 100%;
        background-repeat: no-repeat;
        background-image: url("../assets/img/logo.png");
      }
    }

    .mid {
      box-sizing: border-box;
      display: flex;
      height: 48px;
      align-items: center;
      padding-left: 10px;
      justify-content: space-around;

      .mid-item {
        box-sizing: border-box;
        height: 48px;
        font-size: 16px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        line-height: 48px;
      }

      .active {
        border-bottom: 3px #ffffff solid;
      }

      // .active::after {
      //     content: "";
      //     width: 20px;
      //     height: 5px;
      //     background-color: #fff;
      //     border-radius: 15%;
      //     position: absolute;
      //     bottom: 0;
      //   }

      .mid-item:hover {
        cursor: pointer;
        border-bottom: 3px #ffffff solid;
      }
    }

    .right {
      box-sizing: border-box;
      display: flex;
      justify-content: flex-end;
      height: 100%;
      align-items: center;

      .mid-input {
        width: 200px;
        height: 42px;
        background: #ffffff;
        border-radius: 25px 25px 25px 25px;

        .el-input__inner:focus {
          // border-color: #dcdfe6 !important;
          outline: none !important;
          border: none;
        }

        .el-input__inner {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #aaaaaa;
          outline: none !important;
          border: none;
        }

        .el-input.is-active:focus {
          outline: none;
          border-color: transparent;
        }

        ::v-deep(.el-input__wrapper) {
          border-radius: 25px 25px 25px 25px !important;
        }
      }

      .circle-btn {
        flex-shrink: 0;
        margin-left: 10px;
        text-align: center;
        line-height: 42px;
        width: 42px;
        height: 42px;
        background: #ffffff;
        border-radius: 25px 25px 25px 25px;
        font-size: 14px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #5d3f2c;
      }
    }

    .right-menu {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .right-menu-wrap {
        div {
          width: 25px;
          height: 2px;
          background: #fff;
        }

        .mid-line {
          margin: 8px 0px;
        }
      }

      .right-menu-wrap:hover {
        cursor: pointer;
      }
    }
  }
}

.norem-drawer-wrap {
  display: none;
  visibility: hidden;
  opacity: 0;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0ms;
  position: fixed;
  width: 0;
  // transform: translateX(100%);
  right: 0;
  top: 0;
  height: 100%;
  z-index: -1;
  // background-color: #745b4a;
  // opacity: 0.5;
  background: rgba(93, 63, 44, 1);
  // opacity: 1;
  /* 模糊大小就是靠的blur这个函数中的数值大小 */
  // backdrop-filter: blur(30px);
  padding: 20px;
  // margin-top: -80px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;

  .close-drawer {
    box-sizing: border-box;
    width: 100%;
    height: 30px;
    display: flex;
    justify-content: flex-end;

    .close-img {
      width: 30px;
      height: 30px;

      &:hover {
        cursor: pointer;
      }
    }
  }

  .top-logo {
    box-sizing: border-box;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: center;

    .logo {
      width: 180px;
      height: 60px;
    }
  }

  .menu-list-wrap {
    box-sizing: border-box;
    padding-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;

    .menu-item {
      height: 50px;
      width: 100%;
      color: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 50px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      &:hover {
        cursor: pointer;
        color: #000;
      }
    }

    .menu-item-outter {
      display: flex;
      flex-direction: column;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);

      .first-menu-item {
        display: flex;
        height: 50px;
        justify-content: space-between;

        span {
          line-height: 50px;
          height: 50px;
          width: 100%;
          color: #fff;
          font-size: 18px;
          font-weight: bold;

          &:hover {
            cursor: pointer;
            color: #000;
          }
        }

        .el-icon-right {
          width: 50px;
          height: 50px;
          color: #fff;
        }
      }

      .menu-item-inner {
        display: flex;
        flex-direction: column;
        transition: all 0.5s linear;
        // height: 0;
        padding: 0 20px;
        overflow: hidden;
        max-height: 0px;

        .second-menu-item {
          line-height: 50px;
          height: 50px;
          width: 100%;
          color: #fff;
          font-size: 18px;
          font-weight: bold;
          border-bottom: 1px solid rgba(255, 255, 255, 0.2);

          &:hover {
            cursor: pointer;
            color: #000;
          }
        }

        .second-menu-item:last-child {
          border-bottom: none;
        }
      }

      .openMenu {
        transition: all 0.5s linear;
        max-height: 500px;
      }
    }
  }

  .drawer-btn {
    width: 100%;
    margin-top: 20px;
    display: block;
    background: #fff;
    line-height: 50px;
    height: 50px;
    text-align: center;
    font-size: 18px;
    border-radius: 100px;
    color: rgba(93, 63, 44, 1);
    border: 0;

    &:hover {
      cursor: pointer;
    }
  }
}

.showDrawer {
  z-index: 9999;
  width: 100%;
  transition: all 0.6s cubic-bezier(0.77, 0, 0.175, 1) 0ms;
  // transform: translateX(0);
  display: block;
  visibility: visible;
  opacity: 1;
  margin: 0;
  overflow-y: scroll;
}

@media screen and (min-width: 992px) and (max-width: 1200px) {
  .norem-header-wrap {
    height: 50px !important;
    padding: 0px 20px 0px 20px;
  }
}

@media screen and (max-width: 1200px) {
  .norem-header-wrap {
    height: 50px !important;
    padding: 0px 20px;

    .header {
      .left {
        .left-logo {
          width: 120px;
          height: 40px;
        }
      }
    }
  }

  .norem-drawer-wrap {
    height: 100vh;
  }
}

// @media screen and (min-width: 1921px) {
//   .norem-header-wrap {
//     .header {

//       .left {
//         .left-logo {
//           width: 5vw;
//           height: 2.5vh;
//         }
//       }

//       .mid {

//         .mid-item {
//           font-size: 0.65vw;
//         }
//       }
//     }
//   }
// }
</style>

<style lang="less">
.norem-drop-menu {
  display: flex;
  flex-direction: column;
  align-items: center;

  .el-dropdown-menu__item {
    box-sizing: border-box;
    background: none;
    color: #333;
    padding: 5px;
    font-size: 16px;
    min-width: 200px;
    justify-content: center;
  }

  .el-dropdown-menu__item:hover {
    background-color: #eeebe9 !important;
    color: #333 !important;
  }

  // .popper__arrow {
  //   border: 1px solid #4472cb !important;
  // }

  // .popper__arrow::after {
  //   border-top-color: #46bcff !important;
  //   border-bottom-color: #46bcff !important;
  // }
}
</style>
