const routes = [
  // {
  //   path: "/index",
  //   name: "index1",
  //   redirect: '/index',
  //   component: () => import('@/view/index'),

  // },
  {
    path: '/',
    name: 'index',
    redirect: '/home',
    component: () => import('@/layout/index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        meta: { title: '首页' },
        component: () => import('../view/home'),
      },
      {
        path: '/publication',
        name: 'publication',
        meta: { title: '出版物' },
        component: () => import('../view/publication'),
      },
      {
        path: '/publication/:id',
        name: 'publicationId',
        meta: { title: '出版物' },
        component: () => import('../view/publication'),
      },
      {
        path: '/publicationDetail',
        name: 'publicationDetail',
        meta: { title: '出版物' },
        component: () => import('../view/publicationDetail'),
      },
      {
        path: '/newsCenter',
        name: 'newsCenter',
        meta: { title: '新闻中心' },
        component: () => import('../view/newsCenter'),
      },
      {
        path: '/newsCenterDetail',
        name: 'newsCenterDetail',
        meta: { title: '新闻中心' },
        component: () => import('../view/newsCenterDetail'),
      },
      {
        path: '/academicResearch',
        name: 'academicResearch',
        meta: { title: '学术研究' },
        component: () => import('../view/academicResearch'),
      },
      {
        path: '/academicResearchDetail',
        name: 'academicResearchDetail',
        meta: { title: '学术研究' },
        component: () => import('../view/academicResearchDetail'),
      },
      {
        path: '/introduction',
        name: 'introduction',
        meta: { title: '简介' },
        component: () => import('../view/introduction'),
      },
      {
        path: '/contact',
        name: 'contact',
        meta: { title: '联系我们' },
        component: () => import('../view/contact'),
      },
      {
        path: '/treasure',
        name: 'treasure',
        meta: { title: '馆藏文物' },
        component: () => import('../view/treasure'),
      },
      {
        path: '/three',
        name: 'three',
        meta: { title: '3d' },
        component: () => import('../view/three'),
      },
    ],
  },
]

export default routes
