/***
 * @Author: ZWJ
 * @Date: 2022-11-17 11:24:40
 * @LastEditTime: 2022-11-17 15:50:00
 * @LastEditors: ZWJ
 * @Description:定义中文语言
 */
export default {
  // message: {
  //   loginhello: '你好',
  //   loginTitle: '欢迎登录',
  //   index: '首页',
  //   searchPage: '分享页面',
  // },

  publication: {
    title: '出版物',
    tabs1: '图录',
    tabs2: '文集',
    tabs3: '专著',
    return: '返回列表',
    relatedInformation: '相关信息',
  },

  contact: {
    title: '联系我们',
    contactInformation: '联系方式',
    onlineMessage: '在线留言',
  },

  newsCenter: {
    title: '新闻中心',
    tabs1: '全部',
    tabs2: '馆内新闻',
    tabs3: '行业资讯',
    more: '了解更多',
    topEmpty: '暂无推荐',
    return: '返回列表',
    time: '时间',
    source: '来源',
  },
  academicResearch: {
    title: '学术研究',
    tabs1: '全部',
    tabs2: '学术活动',
    tabs3: '公开课',
    empty: '暂无动态',
    return: '返回列表',
    time: '时间',
    source: '来源',
  },
  Pagination: {
    all: '共计{0}条，每页',
    allShort: '共计{0}条',
    unit: '条',
    tip: '请输入有效的页数',
    goTo: '前往',
  },
  introduction: {
    title: '艺术馆简介',
    OverviewoftheMuseum: '本馆概况',
    Guideservice: '导览服务',
    Collectionappraisal: '藏品鉴定',
    briefintroduction: '简介',
    Iconographicfeatures: '造像特征',
    Guide: '参观指南',
    GuideintheMuseum: '馆内导览',
    Metroline: '地铁线路',
    Busroutes: '公交车线路',
    MetrolineDetail:
      '8号线大涌站A出口，沿环市大道西走210米，过人行道，到路口斜对面并继续向前，沿环市大道西走190米，向左前方转进入大涌东路走40米，向右前方转过人行道，向右前方转，走140米，左转走90米，到达终点。',
    BusDetail:
      '南沙27路，大涌站下车，沿环市大道西走270米，右转走110米，到达终点。',
    NoticetoTourists: '游览需知',
    line1: '请观众在开放时间内持本人有效身份证件入馆参观。',
    line2:
      '凡酗酒者、衣衫不整和穿着拖鞋者、携带宠物、携带易燃易爆、酒类液体及危险品者，无行为能力或限制行为能力且无监护人陪伴者谢绝入馆参观。',
    line3:
      '进入博物馆请维护安静、有序的参观环境。勿高声谈笑、追逐嬉戏，勿随地吐痰、抛弃纸屑杂物，勿在休息座椅上躺卧。',
    line4: '馆内请勿吸烟。',
    line5: '馆内拍照请勿使用闪光灯和三角架等专业器材。',
    line6:
      '根据国家有关规定，除常设的基本陈列和专题展览免费开放外，按照市场化运作举办的临时展览及展演不列入免费参观范围。',
    line7:
      '在场馆内进行讲解、讲学、调查、摄录等活动，请提前向馆方申请，依照规定获得许可后方可实施。',
    line8: '如遇突发事件，请服从工作人员指挥。',
    Visitappointment: '参观预约',
    placeholder: '请输入证书编码查询',
    CertificateCode: '证书编码',
    more: '了解更多',
    WorkDetails: '作品详情',
  },
  treasure: {
    title: '馆藏文物',
    all: '全部',
    Statue: '造像',
    bronzeware: '青铜器',
    Goldandsilverware: '金银器',
    Calligraphyandpainting: '字画',
    information: '相关信息',
    treatureName: '作品名',
    year: '年代',
    area: '文化地区',
    object: '介质',
    size: '尺寸',
    type: '类别',
    num: '证书编码',
    book: '鉴定证书',
    about: '相关作品',
  },
  message: {
    loginhello: '你好',
    loginTitle: '欢迎登录',
    home: '首页',
    artIntroduction: '艺术馆简介',
    Collection: '馆藏文物',
    Statue: '造像',
    bronzeware: '青铜器',
    Goldandsilverware: '金银器',
    Calligraphyandpainting: '字画',
    NewsCenter: '新闻中心',
    NewsintheMuseum: '馆内新闻',
    Industryinformation: '行业资讯',
    academicresearch: '学术研究',
    academicactivities: '学术活动',
    Openclass: '公开课',
    publication: '出版物',
    contactus: '联系我们',
    Pleaseenterkeywords: '请输入关键词',
    Collectionappraisal: '藏品鉴定',
    SiteMap: '网站地图',
    RelatedLinks: '相关链接',
    CopyrightNotice: '版权声明',
    MessageBoard: '留言板',
    Aboutus: '关于我们',
    Scanthecodetoviewthemobilestation: '扫码查看手机站',
    opentime: '开馆时间',
    Stopenteringthelibrary: '停止入馆',
    ClosingHours: '闭馆时间',
    FreefromTuesdaytoSunday: '周二至周日免费开放',
    closedonMonday: '周一闭馆',
    exceptholidays: '节假日除外',
    GandharaArtMuseum: '犍陀罗艺术博物馆',
    copyright: '版权所有',
    Consultingtelephone: '咨询电话',
    AddressLocation: '地址位置',
    companyAddress: '广州市南沙区南沙街锦珠广场环市大道西221号',
    Panoramictour: '全景导览',
    Visitappointment: '参观预约',
    all: '全部',
    searchPage: '分享页面',
    price: '价格',
    yulan: '点击预览',
    goumai: '前往购买',
    more: '查看更多',
    jianjieText: '犍陀罗艺术的主要贡献在于佛像的创造。佛教在前6世纪末兴起后，数百年间无佛像之刻画，凡遇需刻佛本人形像之处，皆以脚印、宝座、菩提树、佛塔等象征。1世纪后，随大乘佛教的流行，信徒崇拜佛像渐成风气，遂有佛像的创作。最初佛像乃从印度民间的鬼神雕像转化而来，而在犍陀罗地区，佛像的制作又较多地吸收了希腊式雕像和浮雕的风格。现存最早的犍陀罗艺术的佛像约作于1世纪中叶，是一块表现释迦牟尼接受商人捐赠花园的浮雕，其中佛和商人、信徒的形像皆用当地流行的希腊风格表现，仅佛头部雕有光轮以显示其神圣。'
  },
}
